import { Grid, makeStyles, Avatar } from '@material-ui/core'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import React from 'react'
import widont from 'widont'

import ButtonLink from '@aletheia/common/components/ButtonLink'
import ContentfulImage from '@aletheia/common/components/ContentfulImage'

import { IPerson } from '../../contentful'
import { SansSerifTypography } from '../../globals/theme'
import Section from './Section'

type FundManagersProps = {
  FundManagers: IPerson[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: theme.palette.primary.light,
  },
  memberPhotosWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    padding: `0 10%`,
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  memberPhoto: {
    width: 200,
    height: 200,
    borderRadius: '50%',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      marginBottom: theme.spacing(3),
    },
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  teamMemberAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  teamMemberAvatarGroup: {
    justifyContent: 'center',
    paddingBottom: theme.spacing(3),
  },
}))

export const FundManagers: React.FC<FundManagersProps> = ({ FundManagers }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Section title="Fund managers that care" maxWidth="md">
        <AvatarGroup className={classes.teamMemberAvatarGroup}>
          {FundManagers.map((TeamMember) => (
            <Avatar
              key={TeamMember.sys.id}
              className={classes.teamMemberAvatar}
            >
              <ContentfulImage
                image={TeamMember.fields.photo}
                widths={[80, 160]}
                sizes=""
                square
              />
            </Avatar>
          ))}
        </AvatarGroup>
        <SansSerifTypography gutterBottom>
          {widont(
            `Our fund managers have many years of experience managing and granting millions of dollars worth of
            donations securely, flexibly, and effectively. Get in touch anytime to discuss your next donation.`,
          )}
        </SansSerifTypography>
        <div className={classes.actions}>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={9} sm={4} md={3}>
              <ButtonLink href="/team" variant="contained" fullWidth>
                Meet the team
              </ButtonLink>
            </Grid>
          </Grid>
        </div>
      </Section>
    </div>
  )
}

export default FundManagers
